.comparePlanTableBlock td:first-child {
    font-weight: bold;
    background-color: #F7F8FC;
    width: 200px !important;
    max-width: 200px !important;
    text-align: left;
    padding-left: 15px;

    border-left: none;
}

.modal:before {
    display: block;
    content: "";
    left: 50%;
    top: 50%;
    position: absolute;
    background-color: #000;
    width: 100vw;
    height: 100vh!important;
    transform: translate(-50%, -50%);
    opacity: .5;
}

.comparePlansDataGroupItem{
    /*page-break-before: always;*/
}

.removeBoxShadow {
    box-shadow: none!important;
    /*padding: 20px 20px;*/
    padding: 0!important;
}

.viewPlanServerDataRow.removeBoxShadow .col-12.tableContainer {
    padding-left: 70px!important;
    padding-right: 70px!important;
    padding-top: 50px!important;
}

.removeBoxShadow .accordion__panel {
    animation: unset!important;
}
.removeBoxShadow thead th {
    padding-top: 40px!important;
}

.removeBoxShadow thead th .planGuaranteeLevelName {
    top: 12px!important
}

/*.removeBoxShadow td{*/
/*    border:0!important;*/
/*    color: #000!important;*/
/*    font-weight: normal!important;*/
/*    box-shadow: unset!important;*/
/*    background-color: #fff!important;*/
/*}*/
.removeBoxShadow #stickyTableHeader,
.removeBoxShadow .getPlanButton,
.removeBoxShadow .accordion-indicator {
    display: none;
}
.table {
    color: #000!important;
    border: 0!important;
}

.comparePlanTableBlock th:first-child {
    width: 200px !important;
    max-width: 200px !important;
    text-align: left;
    padding-left: 15px;

    border-left: none;
}

.comparePlanTableBlock td:last-child,
.comparePlanTableBlock th:last-child {
    border-right: none;
}

.comparePlansDataGroupItem .table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent;
}

.comparePlanTableBlock .table,
.comparePlanTableBlock tbody,
.comparePlanTableBlock tr {
    border: 0
}

.comparePlanTableBlock td {
    border: 1px solid #E0E1E5;
    vertical-align: middle !important;
    width: 190px;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #31345C;
}

.comparePlanTableBlock th {
    vertical-align: middle !important;
    width: 190px;
    border: 1px solid #E0E1E5;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #2F2F31;
}

.comparePlanTableBlock .table {
    margin-bottom: 0;
}

.greenTable tbody tr {
    background-color: #E4F5ED;
}

.greenTable td:first-child {
    background-color: transparent;
    color: #31345C;
}

.comparePlanTableBlock .paymentFrequencyBlock .insurance-main-select__control {
    border-color: #34D29C !important;
}

.comparePlanTableBlock .paymentFrequencyBlock .insurance-main-select__single-value {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
}

.comparePlanTableBlock .paymentFrequencyBlock label {
    font-size: 13px;
    line-height: 16px;
    display: block;
    width: 100%;
    text-align: left;
    padding: 0;
    margin-bottom: 10px;
}

.comparePlanTableBlock .paymentFrequencyBlock .payment-frequency-option {
    width: 100%;
}

.comparePlanTableBlock thead th {
    line-height: 0;
    position: relative;
    border-top: none !important;
    border-bottom: none !important;
}

.comparePlanTableBlock thead th img {
    max-width: 100px;
    max-height: 70px;
    margin-top: 10px;
}

.comparePlanTableBlock thead th .planGuaranteeLevelName {
    left: 50%;
    top: -12px;
    display: flex;
    padding-right: 20px;

    -webkit-transform: translateX(-50%);
    -moz-transform:    translateX(-50%);
    -ms-transform:     translateX(-50%);
    -o-transform:      translateX(-50%);
    transform:         translateX(-50%);
}

.comparePlanTableBlock thead th .planGuaranteeLevelName span {
    top: 5px;
}

.comparePlansDataGroupItem .accordion__button[aria-expanded='true']:after {
    display: none;
}

.comparePlanTableBlock .viewPlanDataGroup {
    padding-bottom: 35px;
}

.planPriceInCompareTable {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #34D29C;
    margin-top: 5px;
    display: block;
}

.planPriceInCompareTable + .getPlanButton {
    margin: 17px 0;
    font-size: 17px;
}

.detailGuaranteesTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #32335C;
}


.mobileTableNavigationRight {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 52px;

    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.mobileTableNavigationRight button {
    width: 113px;
    height: 113px;
    right: -73px;
    top: 50%;
    border-radius: 50%;
    position: fixed;
    -webkit-transform: translateY(-50%);
    -moz-transform:    translateY(-50%);
    -ms-transform:     translateY(-50%);
    -o-transform:      translateY(-50%);
    transform:         translateY(-50%);

    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.mobileTableNavigationRight button span {
    position: absolute;
    top: 50%;
    left: 21px;
    color: #34D29C;
    -webkit-transform: translateY(-50%);
    -moz-transform:    translateY(-50%);
    -ms-transform:     translateY(-50%);
    -o-transform:      translateY(-50%);
    transform:         translateY(-50%);
}

.mobileTableNavigation {
    display: none !important;
}




/* SWITCHER STYLE START */
.detailGuaranteesSwitcher {
    margin-top: 8px;
    margin-bottom: 5px;
}

.detailGuaranteesSwitcher input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
    z-index: -9999999;
}

.detailGuaranteesSwitcher label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 24px;
    background: #32335C;
    display: block;
    border-radius: 100px;
    position: relative;
    margin: 0 auto;
}

.detailGuaranteesSwitcher label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 4px;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 50%;
    transition: 0.3s;
}

.detailGuaranteesSwitcher input:checked + label {
    background: #34D29C;
}

.detailGuaranteesSwitcher input:checked + label:after {
    left: calc(100% - 4px);
    transform: translateX(-100%);
}

.detailGuaranteesSwitcher label:active:after {
    width: 30px;
}
/* SWITCHER STYLE END */


#stickyTableHeader {
    position: sticky;
    top: 0;
    height: 0;
    width: calc(100% + 30px);
    z-index: 2;
    background-color: #FFFFFF;
    margin-left: -15px;
    transform: translateY(-115px);
    visibility: hidden;
    border-bottom: 1px solid #E0E1E5;
    overflow: hidden;

    transition-property: transform;
    transition-duration: 0.3s;
}

#stickyTableHeader > div {
    opacity: 0;
}

#stickyTableHeader.is-visible {
    height: 150px;
    transform: translateY(0);
    visibility: visible;
    overflow: visible;

    transition-property: transform;
    transition-duration: 0.5s;

}

#stickyTableHeader.is-visible > div {
    opacity: 1;

    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -ms-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
}

.fix-head-td {
    position: relative;
    display: inline-block;
    width: calc(100% / 4);
    text-align: center;
    float: left;
    height: 100%;
    padding-left: 5px;
}


.fix-head-img-block {
    height: 70px;
    margin-top: 35px;
}

.fix-head-img-block span {
    height: 100%;
    display: inline-block;
    vertical-align: middle;
}

.fix-head-td img {
    max-width: 60px;
    max-height: 60px;
    vertical-align: middle;
}

.fix-head-td .getPlanButton {
    margin: 5px 0 0 0;
    background-color: #FFFFFF;
    color: #34D29C;
    border: 1px solid #34D29C;
    height: 27px;
    line-height: 27px;
    font-size: 14px;
    width: auto;
    padding: 0 10px;
}

.fix-head-td .getPlanButton:hover {
    background-color: #34D29C;
    color: #FFFFFF;
}

.fix-head-td .getPlanButton:after {
    display: none;
}

.fix-head-td .planPriceInCompareTable {
    border-top: 1px solid #e1e2e6;
    padding-top: 6px;
    margin-left: 5px;
}

#stickyTableHeader .planGuaranteeLevelName {
    display: flex;
    left: 50%;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    top: 6px;
    -webkit-transform: translateX(-50%);
    -moz-transform:    translateX(-50%);
    -ms-transform:     translateX(-50%);
    -o-transform:      translateX(-50%);
    transform:         translateX(-50%);
}

#stickyTableHeader .planGuaranteeLevelName span {
    top: 4px;
}

#stickyTableHeader:before {
    content: '';
    position: absolute;
    left: -99%;
    background-color: #FFFFFF;
    height: 150px;
    width: 100%;

    border-bottom: 1px solid #E0E1E5;

    /*box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);*/
}

#stickyTableHeader:after {
    content: '';
    position: absolute;
    right: -99%;
    background-color: #FFFFFF;
    height: 150px;
    width: 100%;

    border-bottom: 1px solid #E0E1E5;

    /*box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);*/
}

#scrollToTopDynamicButton {
    position: fixed;
    bottom: -100px;
    right: 10%;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 27px;
    background: #34D29C;
    border-radius: 5px;
    padding: 10px 15px;
    color: #FFFFFF;
    border: 2px solid #34D29C;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#scrollToTopDynamicButton.is-visible {
    bottom: 5%;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#scrollToTopDynamicButton:hover {
    background-color: #FFFFFF;
    color: #34D29C;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#scrollToTopDynamicButton span {
    font-size: 20px;
    margin-right: 5px;
    position: relative;
    top: 3px;
}






.comparePlansDataGroupItem td:nth-of-type(even) {
    /*background-color: #f0f0f0;*/
}


.downloadBlock{
    max-width: 770px;
    margin: 0 auto 42px auto;
    background: #FFFFFF;
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
    border-radius: 15px;
    /*height: 72px;*/
}

.downloadBlock .col-md-5,
.downloadBlock .col-md-7{
    align-items: center;
    justify-content: center;
}

.downloadBlock .downloadBlockTitle {
    color: #31345C;

    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 103.8%;
    padding: 28px;
}

.downloadBlock .downloadButton {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 5px;
    background: #34D29C;
    padding: 10px;
    margin: 15px 0;
    cursor: pointer;
    transition: all 0.3s;
}

.downloadBlock .downloadButton:hover {
    box-shadow: 0px 5px 30px #34D29C;
    transition: all 0.3s;
}


/** Votre start**/

#print2 {
    position: absolute;
    left: 999999px;
    max-width: 770px;
    width: 100%;
}

.variablePdfPage {
    width: 100%;
    height: 1056px;
    background: rgb(211,241,227);
    background: linear-gradient(180deg, rgba(211,241,227,1) 0%, rgba(254,255,255,1) 100%);
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.variablePdfPage .backgroundElement {
    z-index: -1;
    position: absolute;
    top: -90px;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80% !important;
}

.variablePdfPage .votre {
    margin: 70px 50px 20px 50px;
    background-color: #FFFFFF;
    border-radius: 15px;
    /*box-shadow: 3px 4px 20px -8px #000;*/
}

.variablePdfPage .plane {
    padding: 0 50px 0 50px;
    width: 100%;
    max-width: 100%;
}

.variablePdfPage .votre .col-4 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 19px;
}

.variablePdfPage .votre .col-8 {
    padding: 30px 15px;
    position: relative;
}

.variablePdfPage .votre .col-8::before{
    content: "";
    position: absolute;
    left: -15px;
    top: 50%;
    bottom: 0;
    width: 1px;
    height: 85%;
    background-color: #000;
    transform: translateY(-50%);
}


.variablePdfPage .votre .col-8 p {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
}

.variablePdfPage .votre .col-8 p span {
    color:  #34D29C;
    font-weight: 700;
    font-size: 19px;
    padding-top: 22px;
    display: block;
}

.variablePdfPage .links {
    margin: 32px 50px 70px 50px;
}

.variablePdfPage .links .col-4 {
    color:  #34D29C;
    font-weight: 600;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.variablePdfPage .links .col-4 img{
    width: 100%;
    max-width: 32px;
    margin-right: 19px;
}

.variablePdfPage .text {
    margin: 0 50px;
}

.variablePdfPage .text p {
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 4px;
}

/** Votre end**/



@media screen and (max-width: 1200px) {

    #scrollToTopDynamicButton {
        right: 4%;
    }

}




@media screen and (max-width: 992px) {

    .planPriceInCompareTable + .getPlanButton {
        font-size: 15px;
    }

    .comparePlanTableBlock {
        width: 700px;
    }

    .comparePlanTableBlock td:first-child {
        width: 180px !important;
        max-width: 180px !important;
    }

    .comparePlanTableBlock td {
        width: 173px;
    }

    #stickyTableHeader {
        position: sticky;
        top: 64px;
    }

    #scrollToTopDynamicButton {
        display: none;
    }

}


@media screen and (max-width: 767px) {
    .planPriceInCompareTable + .getPlanButton {
        font-size: 14px;
    }
    .comparePlanTableBlock {
        width: 100%;
    }

    .comparePlanTableBlock td:first-child {

    }

    #compare-table-one-col .comparePlanTableBlock td:first-child {
        width: 50% !important;
        max-width: 50% !important;
    }

    #compare-table-one-col .comparePlanTableBlock td {
        width: 50%;
    }

    #compare-table-two-col .comparePlanTableBlock {
        width: 540px;
    }

    #compare-table-two-col .comparePlanTableBlock td:first-child {
        width: 180px !important;
        max-width: 180px !important;
    }

    #compare-table-two-col .comparePlanTableBlock td {
        width: 180px !important;
    }

    #compare-table-three-col .comparePlanTableBlock {
        width: 720px;
    }

    #compare-table-three-col .comparePlanTableBlock td:first-child {
        width: 180px !important;
        max-width: 180px !important;
    }

    #compare-table-three-col .comparePlanTableBlock td {
        width: 180px !important;
    }

    #compare-table-three-col .modal-body,
    #compare-table-two-col .modal-body {
        padding: 0;
    }

    .comparePlansContainer {
        padding-top: 30px !important;
        padding-left: 15px !important;
        padding-bottom: 140px !important;
        overflow: scroll !important;
        height: calc(100vh - 50px) !important;
    }

    .comparePlanTableBlock td {
        padding: 5px 15px !important;
    }

    .comparePlanTableBlock .paymentFrequencyBlock label {
        font-size: 12px;
    }

    .comparePlanTableBlock thead th .planGuaranteeLevelName {
        position: absolute;
    }

    .comparePlanTableBlock {
        padding-top: 0;
        margin-top: 0;
    }

    #compare-table-one-col .modal-body {
        padding: 0;
    }

    #compare-table-one-col .comparePlansContainer {
        padding-right: 15px;
        padding-bottom: 50px;
    }

    .mobileTableNavigation {
        display: block !important;
    }

    .mobileTableNavigation .mobileTableNavigationRight {
        opacity: 1;
        transform: translateX(0);

        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }

    .mobileTableNavigation.is-hiding .mobileTableNavigationRight {
        visibility: hidden;
        opacity: 0;
        transform: translateX(100px);

        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.5s;
    }


    /*#stickyTableHeader {*/
    /*    display: none !important;*/
    /*    position: absolute !important;*/
    /*    z-index: -9999 !important;*/
    /*}*/

    #compare-table-one-col .modal-content,
    #compare-table-two-col .modal-content,
    #compare-table-three-col .modal-content {
        padding-bottom: 0;
    }

    .compare-backdrop + .modal {
        overflow: hidden !important;
    }

    #compare-table-one-col .mobileTableNavigationRight {
        display: none;
    }

    #stickyTableHeader {
        top: -31px;
    }

    #stickyTableHeader .planGuaranteeLevelName {
        position: absolute;
    }

    #stickyTableHeader:after,
    #stickyTableHeader:before {
        display: none;
    }

    .fix-head-img-block {
        height: 50px;
        margin-top: 30px;
    }

    .fix-head-td img {
        max-width: 50px;
        max-height: 50px;
    }

    .fix-head-td .planPriceInCompareTable {
        font-size: 16px;
        padding-top: 4px;
        margin-left: 0;
    }

    #stickyTableHeader,
    #stickyTableHeader.is-visible {
        height: 120px;
    }

    #stickyTableHeader.is-visible {
        overflow: hidden;
    }

    .greenTableRow {
        margin-top: -120px;
    }

    #compare-table-two-col #comparePlansContainer,
    #compare-table-three-col #comparePlansContainer {
        padding-left: 0 !important;
    }

    #compare-table-three-col .comparePlanTableBlock .is-sticky td:first-child,
    #compare-table-two-col .comparePlanTableBlock .is-sticky td:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
    }

    .fix-head-td {
        padding-left: 0;
    }

    .comparePlanTableBlock .insurance-main-select__menu {
        min-width: 140px;
    }


}



@media screen and (max-width: 540px) {

    #compare-table-three-col .comparePlanTableBlock {
        width: 480px;
    }

    #compare-table-two-col .comparePlanTableBlock {
        width: 360px;
    }

    #compare-table-three-col .comparePlanTableBlock td,
    #compare-table-two-col .comparePlanTableBlock td {
        width: 120px !important;
    }

    #compare-table-three-col .comparePlanTableBlock td:first-child,
    #compare-table-two-col .comparePlanTableBlock td:first-child {
        width: 120px !important;
        max-width: 120px !important;
    }

    #compare-table-three-col .comparePlanTableBlock th:first-child,
    #compare-table-two-col .comparePlanTableBlock th:first-child {
        width: 260px !important;
        max-width: 260px !important;
    }

    #compare-table-three-col .comparePlanTableBlock td,
    #compare-table-two-col .comparePlanTableBlock td {
        padding: 5px 7px !important;
        font-size: 12px;
        line-height: 16px;
    }

    #compare-table-three-col .planPriceInCompareTable + .getPlanButton,
    #compare-table-two-col .planPriceInCompareTable + .getPlanButton {
        font-size: 10px;
        height: 30px;
        line-height: 30px;
    }

    #compare-table-three-col .comparePlanTableBlock th:first-child,
    #compare-table-two-col .comparePlanTableBlock th:first-child {
        padding: 5px 7px;
    }

    #compare-table-three-col .comparePlanTableBlock .paymentFrequencyBlock .insurance-main-select__single-value,
    #compare-table-two-col .comparePlanTableBlock .paymentFrequencyBlock .insurance-main-select__single-value {
        font-size: 14px;
    }

    #compare-table-three-col .viewPlanDataGroupHead .accordion__button,
    #compare-table-two-col .viewPlanDataGroupHead .accordion__button {
        font-size: 16px;
    }

    .page-break {
        page-break-inside: avoid;
    }

    #compare-table-three-col .comparePlansDataGroupItem .accordion-indicator,
    #compare-table-two-col .comparePlansDataGroupItem .accordion-indicator {
        left: 0;
    }

    #compare-table-three-col .viewPlanDataGroupHead .accordion__button,
    #compare-table-two-col .viewPlanDataGroupHead .accordion__button {
        padding: 11px 25px 11px 50px;
    }

    #compare-table-three-col .viewPlanDataGroupHead,
    #compare-table-two-col .viewPlanDataGroupHead {
        padding: 5px;
    }

    #compare-table-three-col .viewPlanDataGroupItem.not-available .viewPlanDataGroupHead .accordion__button,
    #compare-table-two-col .viewPlanDataGroupItem.not-available .viewPlanDataGroupHead .accordion__button {
        padding-left: 15px;
    }

    .comparePlanTableBlock .greenTable tbody td:first-child {
        padding-right: 0 !important;
    }

    #compare-table-three-col .comparePlanTableBlock,
    #compare-table-two-col .comparePlanTableBlock {
        box-shadow: none;
        border-radius: 0;
    }

    #compare-table-three-col .comparePlanTableBlock thead th .planGuaranteeLevelName,
    #compare-table-two-col .comparePlanTableBlock thead th .planGuaranteeLevelName{
        font-size: 12px;
    }

}

@media print {


    body * {
        visibility: hidden;
    }
    .modal, .modal * {
        visibility: visible;
    }
    .modal {
        position: absolute;
        top: 0;
        left: 0;
        overflow: visible !important;
    }

    .comparePlansDataGroupItem{
        /*page-break-after: always;*/
        page-break-inside: avoid;
        /*-webkit-region-break-inside: avoid;*/
    }

    .viewPlanServerDataRow,
    #comparePlansContainer{
        width: 100%!important;
        max-width: 100% !important;
    }



    .accordion-indicator,
    #scrollToTopDynamicButton,
    #stickyTableHeader,
    .mobileTableNavigation,
    .getPlanButton,
    .downloadBlock,
    .modal-header{
        display: none!important;
    }

    .comparePlanTableBlock td {
        font-size: 18px!important;
    }


}

.viewPlanServerDataRow img{
    max-width: 80%;
}

.viewPlanServerDataRow.removeBoxShadow .col-12 {
    padding-left: 0!important;
    padding-right: 0!important;
}
