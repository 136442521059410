.thankYouPageContainer {
    position: relative;
}

.thankYouPageBlock {
    background: #FFFFFF;
    box-shadow: 0px 5px 30px rgba(55, 87, 124, 0.16);
    border-radius: 15px;
    margin: 150px auto 0 auto;
    width: 670px;
    text-align: center;
    padding: 55px 60px 77px 60px;
}

.thankYouPageÊtreRappeléBlock {
    border-radius: 15px;
    margin: 50px auto 100px auto;
    width: 100%;
    text-align: center;
}

.thankYouPageÊtreRappeléBlock .thankYouPageButton {
    margin: 20px 0;
}

.thankYouPageTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 51px;
    text-align: center;
    color: #31345C;
    margin-bottom: 15px;
}

.thankYouPageDescription {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #91ADC1;
    margin-bottom: 60px;
}

.thankYouPageDescription button {
    text-decoration: underline;
    background: transparent;
    color: #32335C;
}

.thankYouPageIcon img {
    width: 34px;
    margin-bottom: 10px;
}

.thankYouInsurancePage {
    margin: 0;
    width: auto;
}










@media screen and (max-width: 767px) {

    .thankYouPageÊtreRappeléBlock {
        margin: 100px auto;
        padding: 0;
    }

    .thankYouPageBlock {
        width: 100%;
        padding: 30px 20px;
    }

    .thankYouPageDescription {
        margin-bottom: 35px;
    }

}


@media screen and (max-width: 370px) {

    .thankYouPageBlock {
        margin: 120px auto 0 auto;
    }

}


@media screen and (max-width: 350px) {

    .thankYouPageContainer .col-12 {
        padding: 0;
    }

    .thankYouPageButton .mainButtonStyle {
        width: 100%;
        padding: 10px 30px;
    }

    .thankYouPageBlock {
        margin: 100px auto 0 auto;
        width: calc(100% - 30px);
    }

    .thankYouPageÊtreRappeléBlock .thankYouPageButton {
        padding: 0 15px;
    }

}
